<template>
  <span>
    <el-button type="primary" size="mini" class="filter-item" @click="to"> 上传图片 </el-button>
    <PicUploadDialog
      title="上传图片"
      :accept="accept"
      :dialogVisible.sync="dialogVisible"
      @picsReadyHandler="picsReadyHandler"
    />
    <PicUploadTableDialog
      ref="PicUploadTableDialog"
      v-if="uploadTableVisible"
      title="上传图片"
      :dialogVisible.sync="uploadTableVisible"
      :sup_this="sup_this"
      :data="fileList"
      :cols="cols"
      @uploadMorePic="to"
    />
  </span>
</template>
<script>
import PicUploadDialog from '@/components/picUploadDialog'
import PicUploadTableDialog from '../module/picUploadTableDialog'
import { uploadTableDialogCols as cols } from '../../cols'
import { getPicName } from '@/utils'
export default {
  components: {
    PicUploadDialog,
    PicUploadTableDialog
  },
  props: {
    sup_this: {
      type: Object,
      required: true
    },
    accept: {
      type: String,
      default: 'image/jpeg,image/png,image/jpg'
    }
  },
  data() {
    return {
      cols,
      fileList: [],
      dialogVisible: false,
      uploadTableVisible: false
    }
  },
  methods: {
    to() {
      this.dialogVisible = true
    },
    picsReadyHandler(fileList) {
      console.log('fileList', fileList)
      this.fileList = fileList
        .map((item) => {
          if (['jpg', 'jpeg', 'png'].includes(item.name.split('.')[1])) {
            this.$set(item, 'isShare', true)
            this.$set(item, 'title', getPicName(item.name || ''))
            return item
          }
        })
        .filter(Boolean)
      if (!this.fileList.length) {
        this.uploadTableVisible = true
        this.$nextTick(function () {
          this.$message.error('请上传jpg、jpeg或者png格式的图片（请确保后缀是小写）')
        })
        return
      }
      this.uploadTableVisible = true
      this.$nextTick(function () {
        this.$refs.PicUploadTableDialog.check()
      })
    }
  }
}
</script>
